var U = require("../utils/ajax.js");

var getRegion = function (opt) {
  return U.ajax({
    service: 'common.region',
    data: {
     
    },
    succ: function (res, all) {
      opt.succ(res, all);
    },
    fail: function (res, all) {
      opt.fail(res, all);
    }
  });
};

module.exports = {
    getRegion
  };