<template>
  <div class="CitySelection">
    <div class="name">
      <span class="symbol">* </span
      ><span>{{ $t("User.Address.ChooseCity") }} :</span>
    </div>

    <el-cascader
      class="cascader"
      v-model="chooseCity"
      :options="options"
      :props="config"
      @change="changeCity"
    ></el-cascader>
  </div>
</template>
<script>
import { getRegion } from "@/model/Common";
export default {
  name: "CitySelection",
  data() {
    return {
      // cityList: require("@/common/lang/countries.json"),
      chooseCity: this.defaultChoose,
      options: [],
      config: {
        label: "region_name",
        value: "region_name",
        children: "child",
      },
    };
  },
  created() {
    this.goGetRegion();
  },
  props: {
    defaultChoose: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    init() {
      let res = [];
      for (let item in this.cityList) {
        // 当前省份信息
        let provinces = this.cityList[item].provinces;
        // 有省份
        if (provinces) {
          let provincesChildren = [];
          //   遍历省份
          for (let provincesItem in provinces) {
            //   当前地区信息
            let district = provinces[provincesItem].district;
            // 有地区
            if (district) {
              let districtChildren = [];
              //   遍历地区
              for (let districtItem in district) {
                districtChildren.push({
                  value: districtItem,
                  label: districtItem,
                });
              }
              provincesChildren.push({
                value: provincesItem,
                label: provincesItem,
                children: districtChildren,
              });
            } else {
              provincesChildren.push({
                value: provincesItem,
                label: provincesItem,
              });
            }
          }
          res.push({
            value: item,
            label: item,
            children: provincesChildren,
          });
        } else {
          res.push({
            value: item,
            label: item,
          });
        }
      }
      this.options = res;
    },

    goGetRegion() {
      getRegion({
        succ: (res, all) => {
          this.options = this.resolveTree(res.regions, "child");
        },
        fail: () => {},
      });
    },

    // 处理树节点，连级选择器在最后一位是空children时依旧会创建节点导致节点无法选中
    resolveTree(target, childName) {
      for (let i = 0; i < target.length; i++) {
        let item = target[i];
        if (item[childName]) {
          if (item[childName].length) {
            this.resolveTree(item[childName], childName);
          } else {
            delete item[childName];
          }
        }
      }

      return target;
    },

    // 获取树枝最后一位的code值
    getCode(arr, target, callBack) {
      let findVal = arr.shift();
      let val = target.find((item) => item.region_name === findVal);
      if (val) {
        if (val.child?.length) {
          this.getCode(arr, val.child, callBack);
        } else {
          callBack(val.code);
        }
      }
    },

    changeCity(e) {
      if (e.length) {
        this.getCode([...e], this.options, (code) => {
          this.$emit("changeShipAreaCode", code);
        });
      }
      this.$emit("changeCity", e);
    },
  },
};
</script>
<style lang='scss' scoped>
.CitySelection {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    margin-right: 0.75rem;
    width: 110px;
    font-size: 0.75rem;
    color: var(--text-color-minor);
    .symbol {
      color: var(--red-color);
    }
  }

  .cascader {
    flex-grow: 1;
  }
}
</style>